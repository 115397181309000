import React from "react"
import Layout from "../components/layout"
import { Container } from "reactstrap"
import { Link } from "gatsby"
import Head from "../components/head"

// const liStyle = {
//   display: "list-item",
//   listStyleType: "disc",
//   paddingBottom: ".5rem",
//   marginLeft: "2rem",
//   fontSize: "14px",
//   color: "#777575",
// }
const Sitemap = () => {
  return (
    <Layout>
      <Head
        title="Site Map"
        description="A simple site map to help you find your way around ThrowThings.com"
      />

      <Container>
        {/* <!-- breadcrumb start --> */}
        <div className="breadcrumb-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="page-title">
                  <h2>site map</h2>
                </div>
              </div>
              <div className="col-sm-6">
                <nav aria-label="breadcrumb" className="theme-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      site map
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- breadcrumb End --> */}

        {/* <!-- section start --> */}
        <section className="section-b-space sitemap_page">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-6">
                <h5 className="title">home</h5>
                <ul className="">
                  <li>
                    <Link to="/">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      ThrowThings home page
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-6">
                <h5 className="title">general information pages</h5>
                <ul>
                  <li>
                    <Link to="/about">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      about us
                    </Link>
                  </li>
                  <li>
                    <Link to="/returns">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      returns
                    </Link>
                  </li>
                  <li>
                    <Link to="/shipping">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      shipping
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      contact us
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      privacy policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/sitemap">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      site map
                    </Link>
                  </li>
                  <li>
                    <Link to="404.html">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      404
                    </Link>
                  </li>
                </ul>
                <h5 className="title">Blog</h5>
                <ul>
                  <li>
                    <a href="https://blog.throwthings.com">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      throw & tell
                    </a>
                  </li>
                </ul>
                <h5 className="title">Testimonials</h5>
                <ul>
                  <li>
                    <Link to="/nice-words-thrown-our-way">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      nice words thrown our way
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-3 col-6">
                <h5 className="title">product category pages</h5>
                <ul>
                  <li>
                    <a href="https://www.throwthings.com/promotional/">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      Throw Your Name Around
                    </a>
                  </li>
                  <li>
                    <Link to="/throw-your-voice">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      throw your voice
                    </Link>
                    <ul>
                      <li>
                        <Link to="/basic-ventriloquist-dummies">
                          basic ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/standard-upgrade-ventriloquist-dummies">
                          standard upgrade ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/deluxe-upgrade-ventriloquist-dummies">
                          deluxe upgrade ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/super-deluxe-upgrade-ventriloquist-dummies">
                          super deluxe upgrade ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/hand-puppets">hand puppets</Link>
                      </li>
                      <li>
                        <Link to="/marionettes">marionettes</Link>
                      </li>
                      <li>
                        <Link to="/accessories-replacement-stuff">
                          accessories and replacement stuff for your dummy
                        </Link>
                      </li>
                      <li>
                        <Link to="/female-ventriloquist-dummies">
                          female ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/gramps-granny">
                          gramps and granny ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/professional-ventriloquist-dummies">
                          professional ventriloquist dummies
                        </Link>
                      </li>
                      <li>
                        <Link to="/books-dvds-cases-and-stands">
                          Ventriloquism books, dvds, cases and stands
                        </Link>
                      </li>
                      <li>
                        <Link to="/jeff-dunham-stuff">jeff dunham stuff</Link>
                      </li>
                      <li>
                        <Link to="/stretch-ducks">stretch ducks</Link>
                      </li>
                      <li>
                        <Link to="/bonus-bundles">bonus bundles</Link>
                      </li>
                      <li>
                        <Link to="/limited-editions">limited editions</Link>
                      </li>
                      <li>
                        <Link to="/upgrade-certificates">
                          upgrade certificates
                        </Link>
                      </li>
                      <li>
                        <Link to="/puppet-on-a-stick">puppet-on-a-stick</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/tt-things-to-throw">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      things to throw
                    </Link>
                    <ul>
                      <li>
                        <Link to="/things-to-throw">things to throw</Link>
                      </li>
                      <li>
                        <Link to="/boomerangs">boomerangs</Link>
                      </li>
                      <li>
                        <Link to="/throw-some-dice">throw some dice</Link>
                      </li>
                      <li>
                        <Link to="/throwthings-accessories">
                          throwthings accessories
                        </Link>
                      </li>
                      <li>
                        <Link to="/throw-a-punch">throw a punch</Link>
                      </li>
                      <li>
                        <Link to="/bocce-sets">bocce sets</Link>
                      </li>
                      <li>
                        <Link to="/darts-dart-boards">darts & dart boards</Link>
                      </li>
                      <li>
                        <Link to="/baseballs-softballs-t-balls">
                          baseballs, softballs & t-balls
                        </Link>
                      </li>
                      <li>
                        <Link to="/footballs">footballs</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-6">
                <h5 className="title">More Product Category Pages</h5>
                <ul>
                  <li>
                    <Link to="/throwbacks">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      Throwbacks
                    </Link>
                  </li>
                  <li>
                    <Link to="/throw-up">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      Throw Up
                    </Link>
                  </li>
                  <li>
                    <Link to="/throw-a-party">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      Throw A Party
                    </Link>
                  </li>
                  <li>
                    <Link to="/throw-your-money-away">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      Throw Your Money Away
                    </Link>
                  </li>
                  <li>
                    <Link to="/throwthings-things">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      ThrowThings Things
                    </Link>
                  </li>
                  <li>
                    <Link to="/throw-it-on-the-barbie">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      Throw It On The Barbie
                    </Link>
                  </li>
                  <li>
                    <Link to="/throw-things-away">
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>{" "}
                      Throw Things Away
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Section ends --> */}

        {/* <section className="about-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12"></div>
              <div className="col-sm-12">
                <h4>ThrowThings.com, LLC's Privacy Policy</h4>
                <p>
                  Information about our customers is an important part of our
                  business, and we are not in the business of selling it to
                  others. We share customer information only as described below,
                  as needed to process and complete your order, and with
                  subsidiaries ThrowThings.com, LLC controls that either are
                  subject to this Privacy Notice or follow practices at least as
                  protective as those described in this Privacy Notice.
                </p>
                <ul>
                  <li style={liStyle}>
                    <strong>Business Transfers:</strong> As we continue to
                    develop our business, we might sell or buy stores,
                    subsidiaries, or business units. In such transactions,
                    customer information generally is one of the transferred
                    business assets but remains subject to the promises made in
                    any pre-existing Privacy Notice (unless, of course, the
                    customer consents otherwise). Also, in the unlikely event
                    that ThrowThings.com, LLC, or substantially all of its
                    assets are acquired, customer information will of course be
                    one of the transferred assets.
                  </li>
                  <li style={liStyle}>
                    <strong>Protection of ThrowThings.com and Others:</strong>{" "}
                    We release account and other personal information when we
                    believe release is appropriate to comply with the law;
                    enforce or protect the rights, property, or safety of
                    ThrowThings.com, our users, or others. This includes
                    exchanging information with other companies and
                    organizations for fraud protection and credit risk
                    reduction. Obviously, however, this does not include
                    selling, renting, sharing, or otherwise disclosing
                    personally identifiable information from customers for
                    commercial purposes in violation of the commitments set
                    forth in this Privacy Notice.
                  </li>
                  <li style={liStyle}>
                    <strong>Past/Present Client Information:</strong> We may use
                    the names, individually or as a part of a listing, of
                    companies and/or organizations who have ordered from us or
                    on whose behalf an order was placed. (This includes orders
                    of custom imprinted products which were for the use of a
                    company or organization or used a company or organization
                    logo). When products are ordered for use in motion picture,
                    television, or live stage performance we may use the name of
                    the company/organization that placed the order and/or the
                    motion picture/television show/stage performance that the
                    product was ordered for. We use these names as examples of
                    past and present clients and to let the public and potential
                    customers know where our products are being used.
                  </li>
                  <li style={liStyle}>
                    <strong>With Your Consent:</strong> Other than as set out
                    above, you will receive notice when information about you
                    might go to third parties, and you will have an opportunity
                    to choose not to share the information.
                  </li>
                </ul>
                <p>
                  Should we find it necessary to make any substantial changes in
                  the way we use your personal information we will notify you by
                  posting a prominent announcement on our site for a period of
                  time to be determined by ThrowThings.com, LLC. pages.
                </p>
                <p>
                  ThrowThings.com, LLC reserves the right to amend this Privacy
                  Policy from time to time.
                </p>
              </div>
            </div>
          </div>
        </section> */}
      </Container>
    </Layout>
  )
}

export default Sitemap
